<script setup>
import {Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import {onMounted, reactive, ref} from 'vue';

const {name, configs} = defineProps({name: String, configs: Object});

const input = ref();

const config = ref();

const original = reactive({
    url: null,
    width: null,
    height: null,
    size: null,
    mime: null
});

const cropped = reactive({
    width: null,
    height: null
});

onMounted(() => {
    const typeEl = document.getElementById('Image_type');
    config.value = configs[typeEl.value];
    typeEl.addEventListener('change', event => {
        config.value = configs[event.target.value];
        original.url = null;
        original.width = null;
        original.height = null;
        original.size = null;
    });
});

const change = ({coordinates, canvas}) => {
    cropped.width = coordinates.width;
    cropped.height = coordinates.height;
    cropped.top = coordinates.top;
    cropped.left = coordinates.left;
    // canvas.toBlob(blob => console.log(blob), original.mime);
};

const minHeight = () => config.value.ratio ? config.value.minWidth * config.value.ratio[1] / config.value.ratio[0] : 0;
const isWideEnough = () => !original.width || (original.width >= config.value.minWidth);
const isHighEnough = () => !original.height || (original.height >= minHeight());
const isLightEnough = () => !original.size || (original.size < config.value.maxSize);

const onNewFile = event => {
    if (!event.target.files) {
        return;
    }

    original.url = null;

    const file = event.target.files[0];

    original.mime = file.type;
    original.size = file.size;

    const localUrl = URL.createObjectURL(file);
    const imageDom = new Image();
    imageDom.onload = e => {
        original.width = e.target.width;
        original.height = e.target.height;

        if (isLightEnough() && isWideEnough() && isHighEnough()) {
            original.url = localUrl;
        } else {
            input.value.value = '';
        }
    };
    imageDom.src = localUrl;
};

const formatFileSize = sizeBytes => {
    const KB = 1024;
    const MB = KB * 1024;

    if (sizeBytes < KB) {
        return sizeBytes + 'o';
    }

    if (sizeBytes < MB) {
        return Math.round(sizeBytes / KB) + 'Ko';
    }

    return Math.round(sizeBytes / MB) + 'Mo';
};

const formatImageSize = pixels => {
    return pixels + 'px';
};

const formatRatio = ratio => {
    if (!ratio) {
        return 'libre';
    }

    if (ratio[0] === ratio[1]) {
        return 'carré';
    }

    return ratio.join('/');
};
</script>

<template>
    <div class="cropped-image" v-if="config">
        <div class="cropped-image__upload">
            <input ref="input"
                   :name="name"
                   class="form-control"
                   type="file"
                   :required="true"
                   :accept="config.allowedFormats.join(',')"
                   @input="onNewFile"/>

            <input name="crop"
                   type="hidden"
                   :value="JSON.stringify(cropped)"/>
            <input name="maxWidth"
                   type="hidden"
                   :value="config.maxWidth"/>

            <ul class="list-unstyled small text-muted">
                <li v-if="false">Largeur optimale / maximum : {{ formatImageSize(config.maxWidth) }}</li>
                <li>Format(s) autorisé(s) : {{ config.allowedFormats.join(', ') }}</li>
                <li :class="{'text-danger': !isLightEnough()}">
                    Poids maximum :
                    {{ formatFileSize(config.maxSize) }}
                </li>
                <li :class="{'text-danger': !isWideEnough()}">
                    Largeur minimum :
                    {{ formatImageSize(config.minWidth) }}
                </li>
                <li v-if="minHeight()" :class="{'text-danger': !isHighEnough()}">
                    Hauteur minimum : {{ formatImageSize(minHeight()) }}
                </li>
                <li>Ratio : {{ formatRatio(config.ratio) }}</li>
                <li v-if="config.hint">{{ config.hint }}</li>
            </ul>
        </div>

        <div class="cropped-image__cropper" v-if="original.url">
            <Cropper :src="original.url"
                     @change="change"
                     :min-width="config.minWidth"
                     :stencil-props="{aspectRatio: config.ratio ? config.ratio[0] / config.ratio[1] : null}"
                     class="cropped-image__cropper__cropper"/>

            <ul class="list-unstyled small text-muted" v-if="original.size || original.width">
                <li v-if="original.width">Dimensions initiales : {{ original.width }}x{{ original.height }}</li>
                <li v-if="cropped.width">Nouvelles dimensions : {{ cropped.width }}x{{ cropped.height }}</li>
            </ul>
        </div>
    </div>
</template>

<style>
.cropped-image {
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 576px) {
    .cropped-image {
        flex-direction: column;
    }
}

.cropped-image__upload {
    max-width: 370px;
}

.cropped-image .list-unstyled {
    margin-top: .5rem;
}

.cropped-image__cropper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
}

.cropped-image__cropper__cropper {
    min-height: 192px;
    max-height: 600px;

    min-width: 192px;
    max-width: 600px;
}
</style>
