import './admin.css';

import {createApp} from 'vue';
import CroppedImage from './CroppedImage.vue';
import {Chart} from 'chart.js/auto';

document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('new-Image-form')) {
        createApp({components: {CroppedImage}}).mount('#new-Image-form')
    }
});

const ctx = document.getElementById('myChart');

if (ctx) {
    fetch('/api/session/graph')
        .then(response => response.json())
        .then(data => {
            new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: data.labels,
                    datasets: [{
                        label: 'Nb. of sessions',
                        data: data.data,
                        borderWidth: 1,
                        backgroundColor: '#ff9400',
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        }
                    }
                }
            });
        });
}
